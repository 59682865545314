.BrowserBody {
  /* background: white; */
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  background: #91938822;
  color: #51534a;
}

.BrowserBody a {
  color: #51534a;
}

.BrowserBody.Half {
  height: 50%;
  justify-content: flex-start;
  overflow-y: unset;
  /* background: rgba(233,233,233,0.6); */
}

.BrowserIconContainer {
  max-width: 100%;
  max-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.FarmIcon {
  background: #fff8;
  /* border: 0.2vw solid #78be2099; */
  /* background: white; */
  /* filter: drop-shadow(2px 2px 5px #0006); */
  border-radius: 20px;
  width: 200px;
  font-family: neuzeit-grotesk, sans-serif;
  height: 200px;
  cursor: pointer;
  /* box-sizing: border-box; */
}

.FarmIcon h1 {
  text-align: center;
  line-height: 0.9em;
  margin: 0;
  height: 20%;
}

.FarmIcon.Disabled {
  background: #fff8;
  cursor: unset;
  /* color: #c00d1e; */
}

.FarmIcon.Disabled .Paddock {
  fill: #fff8;
  /* stroke: green; */
}

.FarmIconTitle {
  fill: #51534a;
  font-weight: normal;
  font-size: 22px;
}

.AddFarmContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.FileInputContainer {
  width: 345px;
  height: 40px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
}

.FileInputContainer:hover .FileButton {
  background: #51534a;
  color: white;
}

.FileInput {
  font-size: 10000px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.FileButton {
  padding: 0 10px;
  color: #51534a;
  border: 2px solid #51534a;
  background: white;
  height: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  -webkit-transition: -webkit-transform 100ms -webkit-box-shadow 100ms;
  -moz-transition: -moz-transform 100ms -moz-box-shadow 100ms;
  transition: transform 100ms box-shadow 100ms;
}

.FileInputContainer p {
  margin-left: 10px;
}

.AddFarmContainer .ButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.AlphabetContainer {
  padding: 10px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
}
.react-contextmenu .react-contextmenu-item:hover{
background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item{
font-size: 14px;
display: block;
text-decoration: none;
padding: 10px 15px;
cursor: pointer;
user-select: none;
transition: .2s;
position: relative;
margin-bottom: 2px;
font-weight: 500;
display: flex;
align-items: center;
outline: none;
}
.react-contextmenu-item span{
margin-left: 10px;
}
.react-contextmenu-item svg{
font-size: 14px;
}
.react-contextmenu-item .copy{
color:skyblue
}
.react-contextmenu-item .delete{
color:red
}
.react-contextmenu-item .send{
color: blue
}
.react-contextmenu-item .share{
color: green
}
.react-contextmenu-item .watchlist{
color: rebeccapurple
}